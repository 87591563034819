import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"

import CheckBox from "../../components/CheckBox/CheckBox"
import { CheckBoxContainer } from "../../components/CheckBox/styles/Check"
import ContactForm from "../../components/Form/Form"
import { Input, InputLarge } from "../../components/Form/styles/frm"
import GlobalCheckBox from "../../components/GlobalCheckBox/GlobalCheckBox"
import GlobalSelect from "../../components/GlobalSelect/GlobalSelect"

import {
  fetchContactFormTrattamentiAsync,
  selectFormLoading,
  sendContactAsync,
} from "../../redux/contactFormSlice"
import { openErrorToast } from "../../redux/toastSlice"

export default function ContattiContainer({ trattamenti }) {
  const loading = useSelector(selectFormLoading)
  const { register, handleSubmit } = useForm()
  const [checked, setChecked] = useState(0)
  const [time, setTime] = useState("")
  const [accept, setAccept] = useState(false)
  const dispatch = useDispatch()

  const onSubmit = ({ nome, cognome, telefono, comune, email, messaggio }) => {
    if (!accept) {
      dispatch(openErrorToast("Ricorda di accettare i termini"))
      return
    }

    dispatch(
      sendContactAsync({
        nome,
        cognome,
        telefono,
        comune,
        email,
        messaggio,
        sesso: checked == 0 ? "M" : "F",
        fascia_oraria: time,
      })
    )
  }

  return (
    <ContactForm>
      <ContactForm.Wrapper onSubmit={handleSubmit(onSubmit)} data-aos="fade-up">
        <ContactForm.Title>Richiesta di contatto</ContactForm.Title>
        <div style={{ height: 20 }} />
        <ContactForm.Row>
          <Input
            type="text"
            required
            placeholder="Nome"
            {...register("nome")}
          />
          <Input
            type="text"
            required
            placeholder="Cognome"
            {...register("cognome")}
          />
        </ContactForm.Row>
        <ContactForm.Row>
          <Input
            required
            type="phone"
            {...register("telefono")}
            placeholder="Telefono"
          />
          <Input type="città" {...register("comune")} placeholder="Città" />
        </ContactForm.Row>
        <ContactForm.Row>
          <Input
            required
            type="email"
            {...register("email")}
            placeholder="Email"
          />
          <GlobalSelect
            setValue={setTime}
            options={["9-12", "12-14", "14-18", "Dopo le 18"]}
            placeholder={"Seleziona fascia oraria preferita di contatto"}
          />
        </ContactForm.Row>
        <div style={{ height: 20 }} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            margin: 10,
            marginTop: 10,
            marginBottom: 15,
          }}
        >
          <GlobalCheckBox
            onClick={() => setChecked(0)}
            checked={checked == 0}
            id={"check1"}
            label={"Sono un uomo"}
          />
          <GlobalCheckBox
            onClick={() => setChecked(1)}
            checked={checked == 1}
            id={"check2"}
            label={"Sono una donna"}
          />
        </div>
        <div style={{ height: 20 }} />
        <InputLarge
          {...register("messaggio")}
          placeholder={"Messaggio"}
          required
        />
        <CheckBox onClick={() => setAccept(!accept)}>
          continuando accetti i termini e condizioni sulla privacy
          <CheckBox.Input
            type="checkbox"
            checked={accept ? "checked" : false}
          />
          <CheckBox.CheckMark></CheckBox.CheckMark>
        </CheckBox>

        <ContactForm.Button value="Invia" loading={loading} type="submit" />
        <div style={{ height: 200 }} />
      </ContactForm.Wrapper>
    </ContactForm>
  )
}
