import React from "react"
import styled from "styled-components"
import { device } from "../../constants/breakpoints"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 50px;
  flex: 1;
  border-radius: 12px;
  background-color: white;
  padding: 20px;
  margin: 8.5px;

  ::placeholder {
    color: #99a0a9;
  }
  border-width: 0px;
  @media ${device.tablet} {
    width: 80%;
    &[title~="noresize"] {
      width: auto;
    }
  }
`

const Select = styled.select`
  outline: none;
  border: none;
`

export default function GlobalSelect({ options, setValue, placeholder }) {
  return (
    <Container>
      <Select
        onChange={event => setValue(event.target.value)}
        on
        name="items"
        id="items"
      >
        <option value="">{placeholder}</option>
        {options.map((t, i) => (
          <option key={i} value={t}>
            {t}
          </option>
        ))}
      </Select>
    </Container>
  )
}
