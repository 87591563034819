import React, { useEffect } from "react"
import SEO from "../components/seo"

import "../constants/global.css"
import "aos/dist/aos.css"
import Layout from "../components/layout"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import BreadCrumbs from "../components/BreadCrumbs/BreadCrumbs"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchBannerHome,
  selectBanner,
  selectBannerHome,
  selectHomeLoading,
} from "../redux/homeSlice"
import HeroContatti from "../containers/contatti/HeroContatti"

import LoadingView from "../components/LoadingView/LoadingView"
import ContattiContactForm from "../containers/contatti/ContattiContactForm"
import {
  fetchContactFormTrattamentiAsync,
  fetchContattiTrattamentiAllAsync,
  selectContactFormTrattamenti,
  selectFormStartupLoading,
} from "../redux/contactFormSlice"

const Articolo = ({ location }) => {
  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: "contatti",
    crumbSeparator: " / ",
  })

  const dispatch = useDispatch()
  const banner = useSelector(selectBannerHome)
  const loading = useSelector(selectHomeLoading)
  const trattamenti = useSelector(selectContactFormTrattamenti)
  const loadingStartUp = useSelector(selectFormStartupLoading)
  useEffect(() => {
    dispatch(fetchBannerHome())
    dispatch(fetchContattiTrattamentiAllAsync())
  }, [])

  if (loading || !banner || loadingStartUp) {
    return <LoadingView />
  }
  return (
    <Layout contactModal={false}>
      <SEO title="Contatti" />
      <BreadCrumbs crumbs={crumbs} />
      <HeroContatti banner={banner} />
      <ContattiContactForm
        trattamenti={trattamenti}
        style={{ paddingBottom: 180 }}
      />
    </Layout>
  )
}

export default Articolo
