import { Autocomplete } from "@material-ui/lab"
import React, { useEffect } from "react"
import HeroSection from "../../components/HeroSection/HeroSection"
import { cities } from "../../constants/cities"

export default function HeroContatti({ banner }) {
  return (
    <HeroSection img={banner.url_foto}>
      <HeroSection.Feature style={{ paddingRight: "30%", margin: "auto" }}>
        Contattaci
      </HeroSection.Feature>
    </HeroSection>
  )
}
